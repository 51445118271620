import React from "react"
import { Box, Grid, Image } from "@chakra-ui/react"

import cagayan from "../../images/cagayan.png"
import dwiz from "../../images/DWIZ.png"
import iccph1 from "../../images/ICCPH1.png"
import manilaTimes from "../../images/manila-times.png"
import manila from "../../images/Manila.png"
import philstar from "../../images/philstar.png"
import pna from "../../images/pna.png"
import yesPh from "../../images/yes-ph.png"
import lan from "../../images/leave-a-nest.png"

const Media = () => {
  return (
    <Box
      mt={10}
      py={20}
      px={{ xl: "12rem", lg: "8rem", sm: "4rem", base: "1rem" }}
      bgColor="#FBFBFB"
    >
      <Box
        fontSize={{ "2xl": 45, xl: 35, lg: 30, base: 22 }}
        fontWeight={{ lg: 700, base: 600 }}
        textAlign={"center"}
      >
        Featured In
      </Box>
      <Grid
        templateColumns={{ lg: "repeat(3, 1fr)", base: "repeat(2, 1fr)" }}
        gap={{ "2xl": 10, xl: 8, lg: 10, sm: 8, base: 5 }}
        mx={{ "2xl": "20", xl: "14", lg: "8", base: "0.5" }}
        alignItems="center"
        justifyItems="center"
      >
        <Box w={{ "2xl": "50%", xl: "90%", base: "100%" }}>
          <Image src={manila} />
        </Box>
        <Box w={{ "2xl": "50%", xl: "90%", base: "100%" }}>
          <Image src={lan} />
        </Box>
        <Box w={{ "2xl": "50%", xl: "90%", base: "100%" }}>
          <Image src={manilaTimes} />
        </Box>
        <Box w={{ "2xl": "50%", xl: "90%", base: "100%" }}>
          <Image src={dwiz} />
        </Box>
        <Box w={{ "2xl": "50%", xl: "90%", base: "100%" }}>
          <Image src={philstar} />
        </Box>
        <Box w={{ "2xl": "50%", xl: "90%", base: "100%" }}>
          <Image src={yesPh} />
        </Box>
        <Box w={{ "2xl": "50%", xl: "90%", base: "100%" }}>
          <Image src={pna} />
        </Box>
        <Box w={{ "2xl": "50%", xl: "90%", base: "100%" }}>
          <Image src={cagayan} />
        </Box>
        <Box w={{ "2xl": "50%", xl: "90%", base: "100%" }}>
          <Image src={iccph1} />
        </Box>
      </Grid>
    </Box>
  )
}

export default Media
